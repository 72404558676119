import { render, staticRenderFns } from "./mixSelector.vue?vue&type=template&id=1302a4e5&scoped=true"
import script from "./mixSelector.vue?vue&type=script&lang=js"
export * from "./mixSelector.vue?vue&type=script&lang=js"
import style0 from "./mixSelector.vue?vue&type=style&index=0&id=1302a4e5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1302a4e5",
  null
  
)

export default component.exports