import { render, staticRenderFns } from "./weappParams.vue?vue&type=template&id=0ed5c6c2&scoped=true"
import script from "./weappParams.vue?vue&type=script&lang=js"
export * from "./weappParams.vue?vue&type=script&lang=js"
import style0 from "./weappParams.vue?vue&type=style&index=0&id=0ed5c6c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed5c6c2",
  null
  
)

export default component.exports